import React from 'react';
import classnames from 'classnames';
import style from './Checkbox.module.scss';

interface Props {
	checked: boolean;
	disabled?: boolean;
	children: React.ReactNode;
	isCookieBlock?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = ({ checked, disabled, children, isCookieBlock, onChange }: Props) => {
	return (
		<label
			className={classnames(style.label, {
				[style.cookieCheckbox]: isCookieBlock,
				[style.disableCursor]: disabled,
			})}
		>
			<input
				type="checkbox"
				className={style.input}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<span
				className={classnames(style.checkmark, {
					[style.grey]: disabled,
				})}
			/>
			<span>{children}</span>
		</label>
	);
};

export default Checkbox;
