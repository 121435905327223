global.Intl = require('intl');
require('scss/style.scss');
require('@formatjs/intl-locale/polyfill');
require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/en');
require('intl/locale-data/jsonp/en');
require('intl/locale-data/jsonp/lt');
const React = require('react');
const { Provider } = require('components/globalState');
const helperFunction = require('src/helpers/helpers');
const { IntlProvider } = require('react-intl');
const Layout = require('layout/Layout').default;

const config = require(`./config.${process.env.GATSBY_APP}`);

export const onClientEntry = async () => {
	const userLangFromCookies = await helperFunction.getCookie('userLang');

	if (
		userLangFromCookies !== null &&
		userLangFromCookies.length !== 0 &&
		typeof window !== 'undefined' &&
		localStorage.getItem('gatsby-intl-language') === null
	) {
		localStorage.setItem('gatsby-intl-language', userLangFromCookies);
	}

	if (typeof window !== 'undefined') {
		if (window.location.pathname.match('/lt')) {
			localStorage.setItem('gatsby-intl-language', 'lt');
		}
	}
};

export const wrapPageElement = ({ element, props }) => {
	const { languages, originalPath, language, messages } = props.pageContext.intl;
	return (
		<IntlProvider locale={language} key={language} messages={messages}>
			<Provider>
				<Layout languages={languages && languages} originalPath={originalPath}>
					{element}
				</Layout>
			</Provider>
		</IntlProvider>
	);
};

export const onRouteUpdate = () => {
	const statisticalCookie = localStorage.getItem('statisticalCookie');
	const preferenceCookie = localStorage.getItem('preferenceCookie');
	const gaTurnedOn =
		document.cookie.indexOf(`ga-disable-${config.GA_MEASUREMENT_ID}`) === -1 ||
		document.cookie.indexOf(`ga-disable-${config.GA_MEASUREMENT_ID}=false`) > -1;

	const { gtag, turnOnLiveChat } = window;

	if (typeof gtag === 'function') {
		if (gaTurnedOn && statisticalCookie === '1') {
			gtag('config', config.GA_MEASUREMENT_ID, {
				anonymize_ip: true,
				send_page_view: true,
				allow_google_signals: false,
				allow_ad_personalization_signals: false,
			});
		}
	}

	if (!preferenceCookie || preferenceCookie === '1') {
		const livechatScript = document.querySelector(
			'script[src^="//cdn.livechatinc.com/tracking.js"]',
		);

		if (typeof turnOnLiveChat === 'function' && !livechatScript) {
			turnOnLiveChat();
		}
	}
};
