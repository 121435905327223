import React from 'react';
import { createPortal } from 'react-dom';
import Modal from 'react-modal';
import closeIcon from 'assets/images/x.svg';
import style from './modal.module.scss';

interface Props {
	isVisible: boolean;
	hide: () => void;
	children: React.ReactNode;
}

const ModalBase = ({ isVisible, hide, children }: Props) => {
	return isVisible
		? createPortal(
			<Modal
				isOpen={isVisible}
				onRequestClose={hide}
				className={style.modal}
				overlayClassName={style.overlay}
				ariaHideApp={false}
			>
				<img src={closeIcon} alt="close" onClick={hide} className={style.close} />
				{children}
			</Modal>,
			document.getElementById('modalBase'),
		)
		: null;
};

export default ModalBase;
