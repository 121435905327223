import React from 'react';
import { navigate } from 'gatsby';
import routes from 'languages/pageTranslations/routes.json';
import { StorageCookie } from 'enums/StorageCookie';
import { Language } from 'enums/Language';
import style from './LanguageSwitcher.module.scss';
import { useIntl } from "react-intl";

interface Props {
	originalPath: string,
	languageList: string[]
}

const LanguageSwitcher = ({ originalPath, languageList }: Props) => {
	const { locale } = useIntl();
	const changeLocale = (language: string) => {
		localStorage.setItem(StorageCookie.GatsbyLanguage, language);
		const homePageLink = language === Language.LT ? '/' : `/${language}/`;

		if (originalPath !== '/') {
			const path = originalPath.slice(1).replace(/\/$/, ``);
			if (routes[language][path]) {
				navigate(routes[language][path]);
			}
		} else {
			navigate(homePageLink);
		}
	};

	return (
		<div>
			<div className={style.popoverWrapper}>
				<span className={style.popoverTitle}>
					{locale}
				</span>
				<div>
					<span className={style.arrowShadow} />
					<ul className={style.popoverBox}>
						{languageList.map((language: string) => {
							if (language !== locale) {
								return (
									<li
										key={language}
										onClick={() => changeLocale(language)}
									>
										<span className={style.popoverTitle}>{language}</span>
									</li>
								);
							}
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default LanguageSwitcher;
