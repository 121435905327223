import Checkbox from 'components/checkbox/Checkbox';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import style from './cookie.module.scss';
import { Cookies } from 'enums/types';
import cookieIcon from 'assets/images/privacy.svg';
import close from 'assets/images/x.svg';

interface Props {
	onStateChange: Function;
	onClose?: Function;
}

const translations = defineMessages({
	close: {
		id: 'close',
		defaultMessage: 'close',
	},
	cookieIcon: {
		id: 'imageAlt.cookie',
		defaultMessage: 'Vector cookie chip',
	},
});

const SettingsPopup = ({ onStateChange, onClose }: Props) => {
	const { formatMessage } = useIntl();
	const preferenceCookie = localStorage.getItem(Cookies.Preference);
	const statisticalCookie = localStorage.getItem(Cookies.Statistical);

	const [preference, setPreference] = useState(preferenceCookie === '1' || false);
	const [statistical, setStatistical] = useState(statisticalCookie === '1' || false);

	const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as HTMLInputElement)) {
				if (onClose) onClose(true);
			}
		};

		useEffect(() => {
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		});
	};

	const wrapperRef = useRef(null);

	useOutsideAlerter(wrapperRef);

	const turnOffMainScrollbar = () => {
		const documentIsDefined = typeof document === 'object';
		if (documentIsDefined) {
			const body = document.querySelector('body');
			if (body && !body.classList.contains('overflowHiddenCookie')) {
				body.classList.add('overflowHiddenCookie');
			}
		}
	};

	useEffect(() => {
		if (window.innerHeight < 860 && window.innerWidth < 860) {
			turnOffMainScrollbar();
			window.addEventListener('resize', turnOffMainScrollbar);
			return () => window.removeEventListener('resize', turnOffMainScrollbar);
		}
	}, []);

	return (
		<div className={style.popupWrapper} ref={wrapperRef}>
			<div onClick={() => onClose && onClose(true)} className={style.close}>
				<img
					src={close}
					alt={formatMessage(translations.close)}
					className={style.closeImg}
				/>
			</div>
			<img src={cookieIcon} alt={formatMessage(translations.cookieIcon)} width={110} />
			<h4 className={style.popupHeading}>
				<FormattedMessage
					id="cookies.popupTitle"
					defaultMessage="Pervesk cookies explained"
				/>
			</h4>
			<div>
				<Checkbox isCookieBlock checked disabled>
					<FormattedMessage
						id="cookies.necessary"
						defaultMessage="<b>Necessary cookies</b> are essential for the website’s navigation, performance, security and functionality. These cookies are required in order to deliver information and services to you."
						values={{
							b: (...chunks: string[]) => <b>{chunks}</b>,
						}}
					/>
				</Checkbox>
				<Checkbox
					isCookieBlock
					checked={preference}
					onChange={() => setPreference((prevState) => !prevState)}
				>
					<FormattedMessage
						id="cookies.preference"
						defaultMessage="<b>Preference cookies</b> enable our website to remember the information that alters the way it looks or functions. Rejecting this cookie category might disable some website features."
						values={{
							b: (...chunks: string[]) => <b>{chunks}</b>,
						}}
					/>
				</Checkbox>
				<Checkbox
					isCookieBlock
					checked={statistical}
					onChange={() => setStatistical((prevState) => !prevState)}
				>
					<FormattedMessage
						id="cookies.statistical"
						defaultMessage="<b>Statistical cookies</b> help us understand how you use and interact with the website and allow us to make it better. These cookies are anonymous and provide us generalised data."
						values={{
							b: (...chunks: string[]) => <b>{chunks}</b>,
						}}
					/>
				</Checkbox>
			</div>
			<div>
				<button
					className={style.smallButton}
					onClick={() => onStateChange(preference, statistical, false)}
				>
					<FormattedMessage
						id="cookies.button.agreeAndSave"
						defaultMessage="Agree and save"
					/>
				</button>
			</div>
			<div>
				<button
					className={style.colorButton}
					onClick={() => onStateChange(false, false, false, false)}
				>
					<FormattedMessage id="cookies.button.rejectAll" defaultMessage="Reject all" />
				</button>
			</div>
		</div>
	);
};

export default SettingsPopup;
