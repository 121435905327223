import React, { useEffect, useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import privacyIcon from 'assets/images/privacy.svg';
import { disableGoogleAnalytics } from 'helpers/helpers';
import { StateContext } from 'components/globalState';
import { ActionType, Cookies } from '../../enums/types';
import style from './cookie.module.scss';
import SettingsPopup from './SettingsPopup';
import useIsMobile from 'hooks/useIsMobile';
import { Link } from 'gatsby';
import routes from 'languages/pageTranslations/routes.json';

const config = require(`../../../config.${process.env.GATSBY_APP}`);

const gaTrackingId = config.GA_MEASUREMENT_ID;

export const setCookies = (preference: boolean, statistical: boolean) => {
	if (!statistical) {
		document.cookie = disableGoogleAnalytics(gaTrackingId, true);
	}

	if (preference) localStorage.setItem(Cookies.Preference, '1');
	else localStorage.setItem(Cookies.Preference, '0');

	if (statistical) {
		localStorage.setItem(Cookies.Statistical, '1');
		document.cookie = disableGoogleAnalytics(gaTrackingId, false);
	} else {
		localStorage.setItem(Cookies.Statistical, '0');
	}
};

const Cookie = () => {
	const { locale } = useIntl();
	const { store, dispatch } = useContext(StateContext);
	const [isModalShown, setIsModalShown] = useState(false);
	const { isCookiesWarningVisible } = store;
	const isMobile = useIsMobile(700);

	useEffect(() => {
		const isAcceptedCookieExists =
			document.cookie.indexOf(disableGoogleAnalytics(gaTrackingId, false, true)) > -1;
		const isRejectedCookieExists =
			document.cookie.indexOf(disableGoogleAnalytics(gaTrackingId, true, true)) > -1;
		const isCookiePreferenceSet = isAcceptedCookieExists || isRejectedCookieExists;
		if (isCookiePreferenceSet) dispatch({ type: ActionType.HideCookies });
		else dispatch({ type: ActionType.ShowCookies });
	}, []);

	const setCookiesAccepted = (areCookiesAccepted: boolean) => {
		if (!areCookiesAccepted) {
			//@ts-ignore
			window[`ga-disable-${gaTrackingId}`] = true;
			document.cookie = disableGoogleAnalytics(gaTrackingId, true, false);
			dispatch({ type: ActionType.HideCookies });
			location.reload();
		} else {
			document.cookie = disableGoogleAnalytics(gaTrackingId, false, false);
			localStorage.setItem(Cookies.Preference, '1');
			localStorage.setItem(Cookies.Statistical, '1');
			dispatch({ type: ActionType.HideCookies });
			location.reload();
		}
	};

	if (!isCookiesWarningVisible) return null;

	const returnBodyScrollBar = () => {
		const body = document.querySelector('body');
		if (body && body.classList.contains('overflowHiddenCookie')) {
			body.classList.remove('overflowHiddenCookie');
		}
	};

	const updateCookieSettings = (
		preference: boolean,
		statistical: boolean,
		isOpenModal: boolean,
	) => {
		setCookies(preference, statistical);

		dispatch({ type: ActionType.HideCookies });
		setIsModalShown(isOpenModal);
		returnBodyScrollBar();
		return location.reload();
	};

	const toggleCookieSettingsPopup = () => {
		setIsModalShown(true);
	};

	const closeModal = (value: boolean) => {
		setIsModalShown(!value);
		returnBodyScrollBar();
	};

	return (
		<div className={style.wrapper}>
			<div className={style.cookieBox}>
				<div className={classNames(style.flex)}>
					{!isMobile && (
						<img src={privacyIcon} alt={''} width={67} className={style.img} />
					)}
					<div className={style.inline}>
						<p className={style.text}>
							<FormattedMessage
								id="cookies.description"
								defaultMessage="We use cookies to ensure our website functions properly and safely, as well as to analyse the website’s traffic, improve its performance, and customize your experience. If you select Agree, we will also place marketing cookies to provide you with relevant content, including ads, and measure the success of our marketing campaigns. In case you disagree with particular cookies, you may not be able to experience the full functionality of the website. For more information, check our <a>Cookie policy</a>."
								values={{
									a: (...chunks: string[]) => (
										<b>
											<Link to={routes[locale]['cookie-policy']}>
												{chunks}
											</Link>
										</b>
									),
								}}
							/>
						</p>
					</div>
				</div>
				<div className={style.buttonWrapper}>
					<button
						className={classNames(style.smallButton)}
						onClick={() => setCookiesAccepted(true)}
					>
						<FormattedMessage id="cookies.button.accept" defaultMessage="Accept all" />
					</button>
					<button
						className={style.colorButton}
						onClick={() => toggleCookieSettingsPopup()}
					>
						<FormattedMessage
							id="cookies.button.settings"
							defaultMessage="Cookie settings"
						/>
					</button>
				</div>
			</div>
			{isModalShown && (
				<SettingsPopup
					onClose={(value: boolean) => closeModal(value)}
					onStateChange={(
						preference: boolean,
						statistical: boolean,
						isOpenModal: boolean,
					) => updateCookieSettings(preference, statistical, isOpenModal)}
				/>
			)}
		</div>
	);
};

export default Cookie;
