import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/lt';
import Cookie from 'components/cookie/cookie';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import MobileMenu from 'components/mobileMenu/mobileMenu';
import style from 'components/section/section.module.scss';
import { Slice } from 'gatsby';

interface Props {
	languages: string[];
	originalPath: string;
	children?: React.ReactElement;
}

const Layout = ({ children, languages, originalPath }: Props) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const { locale, messages } = useIntl();
	const toggleMenu = () => {
		setMenuOpen(prevState => !prevState)
	};

	return (
		<div>
			{menuOpen ? (
				<MobileMenu
					close={() => toggleMenu}
					translations={messages}
				/>
			) : (
				<div className={style.overflowHidden}>
					<Header openMenu={() => toggleMenu} language={locale} messages={messages} originalPath={originalPath} languages={languages} />
					<div id="modalBase" />
					{children}
					<Footer />
					<Cookie />
				</div>
			)}
		</div>
	);
};

export default Layout
