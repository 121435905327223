import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'gatsby';
import routes from 'languages/pageTranslations/routes.json';
import style from './mobileMenu.module.scss';

interface Props {
	close: () => void;
}

const MobileMenu = ({ close }: Props) => {
	const { locale, messages } = useIntl();

	const MobileMenuNav = {
		row1: [
			{
				to: routes[locale]['personal-account'],
				title: messages['nav.account.personal'],
			},
			{
				to: routes[locale]['business-account'],
				title: messages['nav.account.business'],
			},
		],
		row2: [
			{
				to: routes[locale]['kyc-services'],
				title: messages['nav.services.kyc'],
			},
			{
				to: routes[locale]['white-label-solutions'],
				title: messages['main.section4.title'],
			},
		],
		row3: {
			to: routes[locale]['card-solutions'],
			title: messages['nav.cards'],
		},
		row4: {
			to: routes[locale]['about-us'],
			title: messages['nav.about'],
		},
		row5: {
			to: routes[locale]['pricing'],
			title: messages['nav.pricing'],
		},
		row6: {
			to: routes[locale]['contact'],
			title: messages['button.contact'],
		},
	};
	return (
		<div className={style.menuContent}>
			<div className={style.links}>
				<h6 className={style.h6}>
					<FormattedMessage id="nav.account.title" defaultMessage="Mokėjimų sąskaitos" />
				</h6>
				{MobileMenuNav.row1.map((link) => (
					<Link key={link.title} to={link.to} onClick={close}>
						{link.title}
						<br />
					</Link>
				))}
			</div>
			<div className={style.links}>
				<h6 className={style.h6}>
					<FormattedMessage id="nav.services.title" defaultMessage="API paslaugos" />
				</h6>

				{MobileMenuNav.row2.map((link) => (
					<Link key={link.title} to={link.to} onClick={close}>
						<span dangerouslySetInnerHTML={{ __html: link.title }} />
						<br />
					</Link>
				))}
			</div>
			<div className={style.links}>
				<h6 className={style.h6}>
					<Link to={MobileMenuNav.row3.to} onClick={close}>
						<span className={style.relative}>
							{MobileMenuNav.row3.title}
							<div className={style.blueTag}>
								<FormattedMessage id="nav.new" defaultMessage="new" />
							</div>
						</span>
					</Link>
				</h6>
			</div>
			<div className={style.links}>
				<h6 className={classNames(style.h6, style.lastLink)}>
					<Link to={MobileMenuNav.row4.to} onClick={close}>
						{MobileMenuNav.row4.title}
					</Link>
				</h6>
			</div>
			<div className={style.links}>
				<h6 className={classNames(style.h6, style.lastLink)}>
					<Link to={MobileMenuNav.row5.to} onClick={close}>
						{MobileMenuNav.row5.title}
					</Link>
				</h6>
			</div>
			<a href="https://ib.pervesk.lt/">
				<button className={style.button}>
					<FormattedMessage id="button.log.in" defaultMessage="Log in" />
				</button>
			</a>
			<div className={style.textCenter}>
				<Link to={MobileMenuNav.row5.to} onClick={close} className={style.buttonLink}>
					{MobileMenuNav.row5.title}
				</Link>
			</div>
		</div>
	);
};
export default MobileMenu;
