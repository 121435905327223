import React, { useReducer, createContext, ReactNode } from 'react';
import { ActionType } from '../enums/types';

interface GlobalState {
	isCookiesWarningVisible: boolean;
	emergencyMessage: string;
	isLoginDisabled: boolean;
}

interface Action {
	type: ActionType;
	payload: GlobalState;
}

export const initialState = { isCookiesWarningVisible: false };

export const reducer = (state: GlobalState, action: Action): GlobalState => {
	switch (action.type) {
		case ActionType.ShowCookies:
			return { ...state, isCookiesWarningVisible: true };
		case ActionType.HideCookies:
			return { ...state, isCookiesWarningVisible: false };
		default:
			return state;
	}
};

type ContextValue = {
	store: { isCookiesWarningVisible: boolean };
	dispatch: React.Dispatch<any>; // You can use more specific types for actions if needed
};

export const StateContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
	children: ReactNode;
}

export const Provider = ({ children }: ProviderProps) => {
	const [store, dispatch] = useReducer(reducer, initialState);
	return <StateContext.Provider value={{ store, dispatch }}>{children}</StateContext.Provider>;
};
