import React, { Suspense } from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import style from './header.module.scss';
import logo from 'assets/images/logo.svg';
import phone from 'assets/images/menu/phone.svg';
import safe from 'assets/images/menu/safe.svg';
import cubes from 'assets/images/menu/solution.svg';
import head from 'assets/images/menu/person.svg';
import arrow from 'assets/images/main/arrowBlue.svg';
import LanguageSwitcher from 'components/languageSwitcher/LanguageSwitcher';
import routes from 'languages/pageTranslations/routes.json'

interface Props {
	language: string;
	openMenu: () => void;
	messages: any;
	languages: string[];
	originalPath: string;
}

interface State {
	showMenu: boolean;
	screenWidth: number;
}

const MobileMenu = React.lazy(() => import('components/mobileMenu/mobileMenu'));
const mobileMenuBreakpoint = 1090; // Don't forget to change the value in header.module.scss and languageSwitcher.module.scss too

class Header extends React.Component<Props, State> {
	state = {
		showMenu: false,
		screenWidth: 0
	};

	componentDidMount(): void {
		if (typeof window !== 'undefined') {
			this.removeOverflowHidden();
			window.addEventListener('resize', this.updateScreenWidth);
		}
	}

	toggleMenuHandler = () => {
		if (typeof document !== 'undefined') {
			const body = document.querySelector('body');
			if (body) {
				body.classList.toggle('overflowHidden');
			}
		}

		this.setState(prevState => {
			return { showMenu: !prevState.showMenu };
		});
	};

	updateScreenWidth = () => {
		this.removeOverflowHidden();
		if (window.innerWidth > mobileMenuBreakpoint) {
			this.setState({
				showMenu: false
			});
		}
		this.setState({
			screenWidth: window.innerWidth
		});
	};

	removeOverflowHidden = () => {
		if (typeof document !== 'undefined') {
			const body = document.querySelector('body');
			if (body && body.classList.contains('overflowHidden')) {
				body.classList.remove('overflowHidden');
			}
		}
	};

	render() {
		const { language, messages, languages, originalPath } = this.props;
		const { showMenu, screenWidth } = this.state;

		const links = [
			{
				name: 'nav.account.title',
				list: [
					{ link: routes[language]['personal-account'], img: phone, label: 'nav.account.personal' },
					{ link: routes[language]['business-account'], img: safe, label: 'nav.account.business' }
				]
			},
			{
				name: 'nav.services.title',
				list: [
					{ link: routes[language]['white-label-solutions'], img: cubes, label: 'main.section4.title' },
					{ link: routes[language]['kyc-services'], img: head, label: 'nav.services.kyc' }
				]
			},
			{
				name: 'nav.cards',
				link: routes[language]['card-solutions'],
				newTag: true
			}
		];

		const headerTitle = (list: any) =>
			<>
				{messages[list.name]}
				{list.newTag &&
					<div className={style.blueTag}>
						<FormattedMessage id="nav.new" defaultMessage="new" />
					</div>}
			</>

		return (
			<header className={classNames(style.container, showMenu ? style.fixed : '')}>
				<div className={style.header}>
					<Link to={routes[language]['/']} className={style.logoWrapper}>
						<img src={logo} className={style.logo} alt={messages['image.general.logo']} />
					</Link>
					<div className={style.headerLinks}>
						{links.map((list: any) => {
							return (
								<div key={list.name} className={style.popoverWrapper}>
									{list.link ?
										<Link to={list.link} className={style.popoverTitle}>
											{headerTitle(list)}
										</Link>
										:
										<span className={style.popoverTitle}>
											{headerTitle(list)}
										</span>
									}

									{list.list &&
										<div>
											<span className={style.arrowShadow} />
											<ul className={style.popoverBox}>
												{list.list.map((item: any) => {
													return (
														<li key={item.label}>
															<Link to={item.link} className={style.popoverText}>
																<img src={item.img} alt="" className={style.linkIcon} />
																<p dangerouslySetInnerHTML={{ __html: messages[item.label] }} />
																<span
																	style={{ background: `url(${arrow})` }}
																	className={style.arrow}
																/>
																{item.newTag &&
																	<div className={style.blueTagDropdown}>
																		<FormattedMessage id="nav.new" defaultMessage="new" />
																	</div>}
															</Link>
														</li>
													);
												})}
											</ul>
										</div>
									}
								</div>
							);
						})}
					</div>
					<div className={style.nav}>
						<Link to={routes[language]['about-us']}>
							<FormattedMessage id="nav.about" defaultMessage="About Us" />
						</Link>
						<Link to={routes[language]['pricing']} className={style.newLink}>
							<FormattedMessage id="nav.pricing" defaultMessage="Pricing" />
						</Link>
						<a className={style.button} href="https://ib.pervesk.lt">
							<FormattedMessage id="button.log.in" defaultMessage="Log in" />
						</a>
						<LanguageSwitcher originalPath={originalPath} languageList={languages} />
					</div>
					<div className={style.mobile}>
						<LanguageSwitcher originalPath={originalPath} languageList={languages} />
						<div onClick={this.toggleMenuHandler} className={classNames(style.navIcon, showMenu ? style.open : '')}>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
					</div>
				</div>
				{
					screenWidth < mobileMenuBreakpoint && showMenu &&
					<Suspense fallback={<div>Loading...</div>}>
						<MobileMenu
							close={this.toggleMenuHandler.bind(this)}
						/>
					</Suspense>
				}
			</header>
		);
	}
}

export default Header;
