module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PERVESK","short_name":"PERVESK","description":"PERVESK","start_url":"/","background_color":"#254098","theme_color":"#254098","icon":"src/assets/images/favicon.png","icons":[{"src":"/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"display":"fullscreen","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"698c9010b0875ae56d6f36e8ebf07dfa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/usr/src/app/src/languages","languages":["lt","en"],"defaultLanguage":"lt","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
