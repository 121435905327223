const config = require(`../../config.${process.env.GATSBY_APP}`);
// eslint-disable-next-line import/prefer-default-export
export const validateEmail = (email: string) => {
	// eslint-disable-next-line no-useless-escape
	const rgx =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return rgx.test(email);
};

export const getMessagesByLang = (langTranslations: any, lang: string) => {
	return langTranslations[lang];
};

export const getKeyByValue = (object: any, value: string) => {
	return Object.keys(object).find((key) => object[key] === value);
};

export const getCookie = (cookieName: string) => {
	const name = `${cookieName}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const splittedDecodecCookie = decodedCookie.split(';');
	for (let i = 0; i < splittedDecodecCookie.length; i++) {
		let cookie = splittedDecodecCookie[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name) === 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}
	return '';
};

export function setCookie(cname: string, cvalue: number, exdays: number) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = `expires=${d.toUTCString()}`;
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export const disableGoogleAnalytics = (
	trackingId: string,
	isGADisabled: boolean,
	isShort?: boolean,
) => {
	const d = new Date();
	d.setFullYear(d.getFullYear() + 2);

	if (!isShort) {
		return `ga-disable-${trackingId}=${isGADisabled}; expires=${d.toUTCString()};path=/`;
	}
	return `ga-disable-${trackingId}=${isGADisabled}`;
};

export const gaOptout = () => {
	const disableStr = `ga-disable-${config.GA_MEASUREMENT_ID}`;
	document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/`;
};
