export const SHOW_COOKIES_WARNING = 'SHOW_COOKIES_WARNING';
export const HIDE_COOKIES_WARNING = 'HIDE_COOKIES_WARNING';

export enum ActionType {
	HideCookies = 'HIDE_COOKIES_WARNING',
	ShowCookies = 'SHOW_COOKIES_WARNING',
}

export enum Cookies {
	Statistical = 'statisticalCookie',
	Preference = 'preferenceCookie',
}
